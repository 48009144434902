body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
  overflow-x: clip;
}

#root {
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.markers {
  font-size: 0.7em;
  opacity: 0.4;
  margin-bottom: -0.5em;
  width: calc(100% + 0.5em);
}

.tff-range_container {
  font-size: 0.9em;
  position: absolute;
  width: calc(100% - 3.5em);
  height: 100%;
  top: -0.05em;
  left: -0.2em;
  z-index: 100;
  pointer-events: none;
  transition: top 0.1s ease-in-out;
}

.tff-range:hover .tff-range_container {
  top: -1.5em;
}

.tff-range_current-value {
  position: absolute;
  border: solid 0.4em #fff;
}

.loader {
  width: 4em;
  height: 4em;
  border-radius: 0.5em;
  background-color: #ff6600;
  animation: 6s linear infinite spin reverse;
  opacity: 0.25;
  filter: grayscale(1);
}

.loader__img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  animation: 3s linear infinite spin;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: .875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
